export const Glamarv2Actions = {
    SET_CACHE_SKU: "SET_CACHE_SKU",
};

export const setCachedSku = (data, dispatch) => {
    dispatch({
        type: Glamarv2Actions.SET_CACHE_SKU,
        payload: data,
    });
};
